import React, { useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import theme from '../theme.json'
import { BsLinkedin } from 'react-icons/bs'
import { BsYoutube, BsFillTelephoneFill } from 'react-icons/bs'
import { MdEmail, MdEditSquare } from 'react-icons/md'
import { HiPresentationChartLine } from 'react-icons/hi'
import axios from 'axios'

const API_PATH = 'https://clune-project.fr/api/index.php'//'http://localhost:80/api/index.php'

///////////////////////////////////
/// Style-components
///////////////////////////////////
const easeTitle = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
`
const Page = styled.div`
    height: 92vh;
    padding: 0 15vw 0 15vw;
    overflow: auto;
    display: flex;
    align-items: center;
    @media (max-width: 1200px) {
        padding: 0 5vw 0 5vw;
     };
    @media (max-width: 800px) {
       flex-direction: column;
       padding:  5vh 10% 5vh 10%;
       height: fit-content;
       gap: 5vh;
    }
`
const TextContainer = styled.div`
    height: 100%;
    padding: 12vh 3vw 12vh 3vw;
    display: flex;
    gap: 2vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    animation: 0.5s ${easeTitle};
    @media (max-width: 800px) {
        width: 100%;
        padding: 0px;
     }
`
const FormContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12vh 0 12vh 0;
    gap: 1vw;
    justify-content: center;
    align-items: center;
    width: 50%;
    animation: 1s ${easeTitle};
    @media (max-width: 800px) {
        width: 100%;
        padding: 0px;
     }
`
const Form = styled.form`
    display: flex;
    width: 100%;
    height: 100%; 
    gap: 1vw;
    flex-wrap: wrap;
    justify-content: center;
`
const lineGrowV = keyframes`
    0% {height: 0%}
    100% {height: 70%}
`
const lineGrowH = keyframes`
    0% {height: 0%}
    100% {height: 70%}
`

const Divider = styled.div`
    border-left: 1px solid ${theme.color.primary};
    height: 70%;
    animation: 0.5s ${lineGrowV} 0s ease-out forwards;
    @media (max-width: 800px) {
        width: 70%;
        height: 0px;
        animation-name: ${lineGrowH};
        border-top: 1px solid ${theme.color.primary};
     }
`

const Textfield = styled.input`
    text-align: left;
    font-family: inherit;
    font-size: inherit;
    border: 1px solid ${theme.color.lightGrey};
    border-radius: 0.5rem;
    height: 3.5rem;
    width: ${props => props.wrapline ? '40%' : '83%'};
    padding-left: 0.5rem;
    color: ${theme.color.primary};
    :focus {
        outline: none;
        border-color: ${theme.color.primary} ;
        color: ${theme.color.primary};
    };
    @media (max-width: 800px) {
        width: 100%;
        height: 3rem;
        margin-bottom: 0.5vh;
     }
`
const Submit = styled.input`
    height: 3.5rem;
    font-family: inherit;
    font-size: inherit;
    width: 83%;
    font-weight: bold;
    background-color: ${props => props.isSent ? theme.color.secondary : theme.color.primary};
    color: ${theme.color.white};
    border: 0px solid;
    border-radius: 0.5rem;
    display: flex; 
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    cursor: ${props => props.isSent ? 'normal' : 'pointer'};
    pointer-events: ${props => props.isSent && 'none'};
    @media (max-width: 800px) {
        height: 3rem;
        margin-top: 0.5vh;
     }
`
const PTag = styled.a`
    font-weight: bold;
    font-size: 2rem;
    width: 100%;
    color: ${theme.color.primary};
    @media (max-width: 800px) {
        font-size: 1.5rem;
     }
`
const ATag = styled.a`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    @media (max-width: 800px) {
        font-size: 0.9rem;
     }
`
const Logos = styled.div`
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5vw;
    width: 100%;
    @media (max-width: 500px) {
        gap: 20vw;
        margin-top: 2vh
    }
`
///////////////////////////////////
/// Constant
///////////////////////////////////
const logosList = [
    { logo: <BsLinkedin style={{ transform: 'scale(2)', color: theme.color.primary }} />, url: 'https://www.linkedin.com/company/clune/' },
    { logo: <BsYoutube style={{ transform: 'scale(2)', color: theme.color.primary }} />, url: 'https://www.youtube.com/channel/UCvFDb9kjRuCYFNs35sY7paw' },
    { logo: <MdEmail style={{ transform: 'scale(2)', color: theme.color.primary }} />, url: 'mailto:contact@clune.fr' },
]

const arrayToObject = (fields) => {
    const obj = {}
    fields.forEach(field => {
        obj[field.name] = field.value
    })
    return obj
}



const fields = [
    { name: 'Nom', required: true, wrapline: true, value: null },
    { name: 'Prénom', required: true, wrapline: true, value: null },
    { name: 'Email', required: true, value: null },
    { name: 'Téléphone', required: false, value: null },
    { name: 'Poste', required: false, value: null },
    { name: 'Entreprise', required: false, value: null },
    { name: 'Commentaire', required: false, value: null },

]
///////////////////////////////////
/// Component
///////////////////////////////////
export default function Contact() {
    const [isSent, setIsSent] = useState(false)
    const submitRef = useRef()
    const onSubmit = (event) => {
        event.preventDefault()
    }
    const handleFormSubmit = (event) => {
        const isValid = fields.every(item => item.required ? item.value : true)
        const objectFields = arrayToObject(fields)
        if (isValid) {
            axios.post(`${API_PATH}`, objectFields).then(res => {
                if (res.status === 200) setIsSent(true)
                else setIsSent(false)
            })
        }
        event.preventDefault()
    }
    return (
        <Page>
            <TextContainer>
                <PTag>Réservez une démo.</PTag>
                <ATag>Clune est la plateforme BIM qui vous aide à piloter votre chantier !</ATag>
                <ATag />
                <ATag>
                    <BsFillTelephoneFill style={{ color: theme.color.primary }} />
                    Nous vous recontactons le plus vite possible.
                </ATag>
                <ATag> <HiPresentationChartLine style={{ color: theme.color.primary }} />
                    Nous vous présentons le produit de A à Z.</ATag>
                <ATag><MdEditSquare style={{ color: theme.color.primary }} />
                    Nous adaptons l'outil pour vous.</ATag>
                <Logos>
                    {logosList.map((item) => (
                        <a key={item.url} href={item.url} target='_blank' rel='noreferrer'>
                            {item.logo}
                        </a>
                    ))}
                </Logos>
            </TextContainer>
            <Divider />
            <FormContainer>
                <Form>
                    {fields.map((field) =>
                        <Textfield
                            disabled={isSent}
                            key={field.name}
                            required={field.required}
                            wrapline={field.wrapline}
                            autocomplete
                            type={field.name === 'Email' ? 'email' : 'text'}
                            placeholder={`${field.name}${field.required ? '*' : ''}`}
                            onChange={(event) => field.value = event.target.value} />
                    )}
                    <div
                        style={{
                            color: theme.color.grey,
                            width: '83%',
                            fontSize: '0.8rem',
                            fontStyle: 'italic'
                        }}>
                        * Obligatoire
                    </div>
                    <Submit
                        ref={submitRef}
                        type='submit'
                        isSent={isSent}
                        value={isSent ? 'Envoyé !' : 'Valider le formulaire'}
                        onSubmit={onSubmit}
                        onClick={event => handleFormSubmit(event)} />

                </Form>

            </FormContainer>
        </Page>
    )

}