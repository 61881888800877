import React from 'react'
import styled, { keyframes } from 'styled-components'
import theme from '../theme.json'
import Thomas from '../medias/Thomas.png'
import Maxime from '../medias/Maxime.png'


///////////////////////////////////
/// Style-components
///////////////////////////////////

const lineGrow = keyframes`
    0% {width: 0%}
    100% {width: 80%}
`

const easeTitle = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
`
const Page = styled.div`
    height: 92vh;
    padding: 5vh 20vw 5vh 20vw;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 1100px) {
        padding: 5vh 12vw 5vh 12vw;
    }
`

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    color: ${theme.color.primary}};
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    animation-name: ${easeTitle};
    animation-duration: 1s;
    animation-timing-function: ease-out;
    @media (max-width: 860px) {
        font-size: 1.2rem;
    };
`

const Paragraph = styled.div`
    color: ${theme.color.black};
    font-size: 1rem;
    text-align: center;
    animation: ${easeTitle} 1s ease-out;
`
const HContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 5vh;
`
const HDivider = styled.div`
    border-bottom: 1px solid ${theme.color.primary};
    animation: 0.5s ${lineGrow} 0s ease-out forwards;
`

const Team = styled.div`
    display: flex;
    gap: 20vw;
    justify-content: center;
`

const Avatar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: ${easeTitle} 1s ease-out forwards;
    animation-delay: ${props => props.index === 0 ? '0.2s' : '0.4s'};
`

const Image = styled.img`
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
`

const Name = styled.div`
    margin-bottom: 5px;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${theme.color.primary};
`

const Function = styled.div`
    font-size: 1rem;
    color: ${theme.color.grey};
`


///////////////////////////////////
/// Constant
///////////////////////////////////
const teamList = [
    { name: 'Thomas', function: 'Cofondateur', picture: Thomas },
    { name: 'Maxime', function: 'Cofondateur', picture: Maxime },
]


///////////////////////////////////
/// Component
///////////////////////////////////
export default function Aboutus() {

    return (
        <Page>
            <Title>
                Notre vision
            </Title>
            <Paragraph>
                La construction d’un ouvrage dépend de facteurs extérieurs difficiles à prévoir.
                <br/>
                Avec Clune, nous voulons proposer aux opérationnels des indicateurs permettant de réagir aux aléas.
                Nous croyons que donner les moyens de prendre des décisions plus éclairées lors d’un imprévu permettra d’optimiser la production.
            </Paragraph>
            <HContainer>
                <HDivider />
            </HContainer>
            <Title>
                L'équipe
            </Title>
            <Team>
                {teamList.map((item, index) => (
                    <Avatar key={item.name} index={index}>
                        <Image src={item.picture} style={{ height: 80 }} alt={item.name} />
                        <Name>{item.name}</Name>
                        <Function>{item.function}</Function>
                    </Avatar>
                ))}
            </Team>
            <Paragraph>
                Thomas et Maxime ont travaillé comme ingénieurs méthodes chez Vinci Construction pendant 4 ans.
                Ils ont participé à de multiples projets (Tours The Link, Tour Hekla, Agro Paris Tech, A7/A8 Paris-Austerlitz...).
                Ils croient fermement à la démocratisation des nouvelles technologies pour le terrain, notamment grâce au BIM. Ils pensent
                que valoriser les données techniques accélèrera l'appropriation de ces innovations et leurs développements.
            </Paragraph>
        </Page>
    )

}

