import React, { useEffect, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import theme from '../theme.json'
import Logo from '../medias/Logo_inverse_C_300px.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { FiMenu } from 'react-icons/fi'


///////////////////////////////////
/// Style-components
///////////////////////////////////
const Bar = styled.div`
    height: 8vh;
    background-color: ${theme.color.primary};
    color: ${theme.color.white};
    padding: 0 15vw 0 15vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1100px) {
        padding: 0 20px 0 10px;
    }
`

const TitleSide = styled.div`
    display: flex;
    align-items: center;
`

const ButtonsSide = styled.div`
    display: flex;
    align-items:center;
    gap: 2vw;
`

const Button = styled.div`
    margin: ${props => props.vertical ? '0 20px 0 20px' : 'none'};
    padding: ${props => props.vertical ? '20px 10px 20px 10px' : '0px'};
    font-size: 1rem;
    font-weight: bold;// ${props => props.vertical ? 'bold' : 'normal'};
    color: ${props => props.selected ? theme.color.secondary : props.color};
    cursor: pointer;
    border-top: ${props => props.borderTop ? `solid 1px ${theme.color.primary}` : 'none'};
    @media (max-width: 1100px) {
        font-size: 1rem;
    }
`
const hoverButton = keyframes`
    0% {background-color: ${theme.color.white}}
    100% {
        border: 1px solid ${theme.color.white};
        color: ${theme.color.white};
        background-color: ${theme.color.primary};
    }
`
const DemoButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    cursor: pointer;
    background-color: ${props => props.selected ? theme.color.primary : theme.color.white};
    border-radius: 0.5rem;
    border: ${props => props.selected ? `1px solid ${theme.color.secondary}` : `1px solid ${theme.color.white}`};
    color: ${props => props.selected ? theme.color.secondary : theme.color.primary};
    padding: 0.4rem;
    font-weight: bold;
    ${props => !props.selected && css`
    &:hover {
        animation: ${hoverButton} 0.5s forwards;
    }
    `}
    
`
const MenuButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const Mask = styled.div`
    position: absolute;
    top: 8vh;
    left: 0;
    height: 92vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
`

const translateMenu = keyframes`
    0% {width: 0}
    100% {width: 200px}
`

const Menu = styled.div`
    position: absolute;
    z-index: 1;
    top: 8vh;
    right: 0;
    height: 92vh;
    background-color: ${theme.color.white};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    animation: ${translateMenu} 0.5s forwards;
`

///////////////////////////////////
/// Constant
///////////////////////////////////
const buttonsList = [
    { url: '/', name: 'Accueil' },
    { url: '/fonctionnalites', name: 'Fonctionnalités' },
    { url: '/apropos', name: 'A propos' },
    { url: '/contact', name: 'Contact' },
]

///////////////////////////////////
/// Component
///////////////////////////////////
export default function AppBar() {

    const navigate = useNavigate()
    const location = useLocation()
    const [selected, setSelected] = useState('Accueil')
    const [display, setDisplay] = useState(false)

    const handleClick = () => {
        setDisplay(!display)
    }

    useEffect(() => {
        const found = buttonsList.find((item) => item.url === location.pathname)
        setSelected(found.name)
    }, [location])

    return (
        <Bar>
            <TitleSide>
                <img src={Logo} alt="logo-Clune" style={{ maxHeight: '6vh', cursor: 'pointer' }}
                    onClick={() => navigate(buttonsList[0].url)}
                />
            </TitleSide>
            <Breakpoint customQuery="(min-width: 900px)">
                <ButtonsSide>
                    {buttonsList.map((item, index) => (
                        index < 3 &&
                        <Button
                            key={item.name}
                            borderLeft={index}
                            selected={selected === item.name}
                            color={theme.color.white}
                            onClick={() => navigate(item.url)}
                        >
                            {item.name}
                        </Button>
                    ))}
                <DemoButton selected={selected === 'Contact' }onClick={() => navigate('/contact')}> Réservez une démo</DemoButton>
                </ButtonsSide>
            </Breakpoint>
            <Breakpoint customQuery="(max-width: 900px)">
                <MenuButton onClick={handleClick}>
                    <FiMenu style={{ transform: 'scale(1.5)', color: theme.color.white }} />
                    {display &&
                        <>
                            <Mask />
                            <Menu>
                                {buttonsList.map((item, index) => (
                                    <Button
                                        key={item.name}
                                        vertical={true}
                                        selected={selected === item.name}
                                        borderTop={index}
                                        color={theme.color.primary}
                                        onClick={() => navigate(item.url)}
                                    >
                                        {item.name}
                                    </Button>
                                ))}
                            </Menu>
                        </>
                    }
                </MenuButton>
            </Breakpoint>
        </Bar>
    )

}